require('babel-polyfill');

const config = new (require('./config'))();
const { getUser, getTenant, getAppList, baseUrl } = require('./comm');

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = `${baseUrl}/resources/autogenerated/qlik-styles.css`;
document.head.appendChild(link);

const script = document.createElement('script');
script.src = `${baseUrl}/resources/assets/external/requirejs/require.js`;
script.onload = async () => {
  requirejs.config({
    baseUrl: baseUrl + '/resources',
    webIntegrationId: config.webIntegrationId,
  });

  // build a single-sign on URL and return back here once completed:
  const loginUrl = new URL(`${baseUrl}/login`);
  loginUrl.searchParams.append('returnto', location.href);
  loginUrl.searchParams.append(
    'qlik-web-integration-id',
    config.webIntegrationId
  );

  const loginBtn = document.querySelector('#login');
  loginBtn.addEventListener('click', () => {
    location.href = loginUrl;
  });

  const logoutBtn = document.querySelector('#logout');
  logoutBtn.addEventListener('click', () => {
    location.href = new URL(`${baseUrl}/logout`);
  });

  const [user, tenant] = await Promise.all([getUser(), getTenant()]);
  if (user || tenant) {
    loginBtn.disabled = true;
    logoutBtn.disabled = false;
    document.querySelector('.logged_in').style.opacity = 0;
    document.querySelector('.logged_out').style.opacity = 1;
    document.querySelector('#user').innerHTML = user.name;
    document.querySelector('#tenant').innerHTML = tenant.name;
    try {
      initMashup();
    } catch (err) {
      console.log(err);
    }
  } else {
    loginBtn.disabled = false;
    logoutBtn.disabled = true;
    document.querySelector('.logged_in').style.opacity = 1;
    document.querySelector('.logged_out').style.opacity = 0;
  }
};

document.body.appendChild(script);

function renderError(error) {
  document.querySelector(
    '#QV01'
  ).innerHTML = `Failed to render charts: <pre><code>${
    error.error || error.stack || error.message || error
  }</code></pre>`;
}

async function initMashup() {
  const list = await getAppList();
  const ulElement = document.createElement('ul');

  list.data.forEach((appItem) => {
    const liElement = document.createElement('li');
    liElement.innerHTML = `<b>${appItem.name}</b> - ${appItem.resourceId}`;
    ulElement.appendChild(liElement);
  });
  document.querySelector('#app_list').appendChild(ulElement);

  const testScript =
    "Characters: Load Chr(RecNo()+Ord('A')-1) as Alpha, RecNo() as Num autogenerate 26;   ASCII: Load   if(RecNo()>=65 and RecNo()<=90,RecNo()-64) as Num,  Chr(RecNo()) as AsciiAlpha,   RecNo() as AsciiNum autogenerate 255  Where (RecNo()>=32 and RecNo()<=126) or RecNo()>=160 ;   Transactions: Load  TransLineID,   TransID,  mod(TransID,26)+1 as Num,  Pick(Ceil(3*Rand1),'A','B','C') as Dim1,  Pick(Ceil(6*Rand1),'a','b','c','d','e','f') as Dim2,  Pick(Ceil(3*Rand()),'X','Y','Z') as Dim3,  Round(1000*Rand()*Rand()*Rand1) as Expression1,  Round(  10*Rand()*Rand()*Rand1) as Expression2,  Round(Rand()*Rand1,0.00001) as Expression3; Load   Rand() as Rand1,  IterNo() as TransLineID,  RecNo() as TransID Autogenerate 3000  While Rand()<=0.5 or IterNo()=1;  Comment Field Dim1 With 'This is a field comment';";
  const appIds = list.data
    .filter((appItem) => appItem.name.toLowerCase().indexOf('helpdesk') !== -1)
    .map((appItem) => appItem.resourceId);

  requirejs(['js/qlik'], (qlik) => {
    const appId = config.appId || (appIds.length ? appIds[0] : null); 
    const app = qlik.openApp(appId, config);
    const sessionAppFromApp = qlik.sessionAppFromApp(
      appId,
      config
    );
    const sessionApp = qlik.sessionApp(config);

    sessionApp.setScript(testScript).then(() => {
      sessionApp.getScript().then((script) => {
        sessionApp.doReload().then(function (result) {
          sessionApp.visualization
            .create('barchart', ['Dim1', '=Sum([Expression1])'], {
              title: 'Session App - Bar Chart',
            })
            .then(function (vis) {
              vis.show('QV03');
            });
        });
      });
    });
    app.getScript().then((appScript) => {
      sessionAppFromApp.getScript().then((sessionAppScript) => {
        document.getElementById('session_app_status').innerHTML =
          appScript.qScript === sessionAppScript.qScript;
      });
    });
    app.on('error', renderError);
    sessionAppFromApp.on('error', renderError);
    app.getObject('CurrentSelections', 'CurrentSelections');
    //Create visualizations
    app.visualization
      .create(
        'piechart',
        [
          'Case Timetable',
          "=Count( {$<Status -={'Closed'} >} Distinct %CaseId )",
        ],
        {
          title: 'Total drug cases per continent',
        }
      )
      .then((vis) => vis.show('QV01'))
      .catch((error) => renderError(error));

    app.visualization
      .get('298bbd6d-f23d-4469-94a2-df243d680e0c')
      .then((vis) => vis.show('QV02'))
      .catch((error) => renderError(error));
  });
}
